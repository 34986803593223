<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>銷售應用中心</b-breadcrumb-item>
            <b-breadcrumb-item active>分店庫存管理</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <b-overlay :show="showLoading" rounded="sm">
        <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
          <h4 class="col-12 col-xl-2 mb-1 mb-xl-0 font-weight-bold">
            分店庫存管理
          </h4>
          <div
            class="
              col-12 col-xl-6
              d-flex
              flex-column flex-xl-row
              align-items-start align-items-xl-center
            "
          >
            <b-form-select
              id="branch-select"
              v-model="selectedBranch"
              :options="branchOptions"
              @change="handleBranchChange"
            ></b-form-select>
            <b-form-select
              v-model="query.merchant_id"
              :options="merchants"
              :disabled="merchants.length == 2"
              @change="handlePageReset"
            ></b-form-select>
            <b-input-group class="ml-0 ml-xl-4">
              <b-form-input
                placeholder="搜尋商品"
                v-model="query.keyword"
                v-on:keyup.enter="handlePageReset"
              ></b-form-input>
              <b-input-group-append>
                <b-button @click="handlePageReset"
                  ><i class="fa fa-search"></i
                ></b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>
        <b-table responsive striped hover :items="productSkus" :fields="fields">
          <template #cell(name)="data">
            <router-link
              :to="{
                name: 'ProductDetail',
                params: {
                  product_id: data.item.product.id,
                },
              }"
            >
              <div class="mb-1">
                {{
                  data.item.product.title == data.item.sku_name
                    ? data.item.product.title
                    : data.item.product.title + " " + data.item.sku_name
                }}
              </div>
              <div>{{ data.item.sku_code }} {{ data.item.sku_outer_code }}</div>
            </router-link>
          </template>
          <template #cell(picture)="data">
            <b-img-lazy
              v-if="
                data.item.product.images[0] &&
                data.item.product.images[0].pic_url
              "
              class="thumb-image"
              :src="
                data.item.product.images[0] &&
                data.item.product.images[0].pic_url
              "
              alt="商品圖片"
              width="50"
              height="50"
            />
          </template>
          <template #cell(max_quantity)="data">
            <div v-if="data.item.isEditing">
              <b-form-input v-model="data.item.max_quantity" type="number" />
            </div>
            <div v-else>
              {{ data.item.max_quantity }}
            </div>
          </template>
          <template #cell(sold_quantity)="data">
            <div class="mb-1">
              {{ data.item.max_quantity - data.item.quantity }}
            </div>
          </template>
          <template #cell(arrange)="data">
            <b-button
              class="ml-2"
              variant="inverse-warning"
              v-if="checkPermission([consts.BRANCH_PRODUCT_STOCK_MODIFY])"
              @click="
                data.item.isEditing
                  ? updateProductSkuMaxQuantity(
                      data.item,
                      data.item.max_quantity
                    )
                  : toggleEditMode(data.item)
              "
            >
              <span class="mdi mdi-lead-pencil mr-1"></span>
              {{ data.item.isEditing ? "更新" : "編輯" }}
            </b-button>
          </template>
        </b-table>
        <CustomPagination
          :currentPage="query.page"
          :totalRows="totalRows"
          :perPage="query.per_page"
          @page-change="handlePageChange"
          @per-page-change="handlePerPageChange"
        />
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import productApi from "../../../apis/product";
import merchantApi from "../../../apis/merchant";
import groupbuy from "../../../apis/groupbuy";
import PermissionChecker from "@/utils/PermissionChecker";
import * as consts from "@/consts";
import { updateQueryFromRoute, updateUrl } from "@/utils/updateUrl";
import CustomPagination from "@/components/Page/Dashboard/CustomPagination.vue";
import { mapGetters, mapState, mapActions } from "vuex";

export default {
  components: { CustomPagination },
  data() {
    return {
      consts,
      showLoading: false,
      totalRows: 0,
      fields: [
        {
          key: "name",
          label: "商品名稱/編號",
        },
        {
          key: "picture",
          label: "商品圖片",
        },
        {
          key: "max_quantity",
          label: "可銷售數量",
        },
        {
          key: "skus_quantity",
          label: "剩餘數量",
        },
        {
          key: "sold_quantity",
          label: "已銷售數量",
        },
        {
          key: "arrange",
          label: "管理",
        },
      ],
      permissionChecker: new PermissionChecker(),
      initialized: false,
      query: {
        page: 1,
        per_page: 10,
        keyword: "",
        branch_id: null,
        merchant_id: null,
      },
      productSkus: [],
      selectedBranch: this.currentBranch ? this.currentBranch.id : null,
      merchants: [{ value: null, text: "請選擇" }],
    };
  },
  watch: {
    queryWatcher: {
      handler() {
        if (!this.initialized) return;
        updateUrl(this.query, this.$store, this.$router);
      },
    },
    $route(to) {
      if (!this.initialized) return;
      this.query = { ...to.query };
      this.fetchSkus();
    },
    selectedBranch(newVal) {
      console.debug("selectedBranch changed:", newVal);
    },
  },
  computed: {
    queryWatcher() {
      return `${this.query.page}-${this.query.per_page}`;
    },
    ...mapState("general", ["myBranches", "currentBranch"]),
    ...mapGetters("general", ["branchOptions"]),
  },
  async created() {
    this.query = updateQueryFromRoute(this.$route, this.$store, this.query);
  },
  async mounted() {
    await this.fetchMerchants();
    await this.fetchSkus();
    if (!this.currentBranch) {
      if (this.myBranches.length) {
        this.selectedBranch = this.myBranches[0].id ?? null;
      }
    } else {
      this.selectedBranch = this.currentBranch.id;
    }
    this.initialized = true;
  },
  methods: {
    ...mapActions("general", ["setCurrentBranch"]),

    async fetchMerchants() {
      const { data } = await merchantApi.list();

      this.merchants = [
        { value: null, text: "請選擇" },
        ...data.data.map((merchant) => {
          return {
            value: merchant.id,
            text: `${merchant.name} (${merchant.type_name})`,
          };
        }),
      ];

      let defaultMerchant = this.findDefaultMerchant(data.data);

      if (defaultMerchant) {
        this.query.merchant_id = defaultMerchant.id;
      }
    },
    findDefaultMerchant(merchants) {
      if (merchants.length == 1) {
        return merchants[0];
      }
      return (
        merchants.find(
          (merchant) => merchant.type === this.defaultMerchantType
        ) ?? merchants.find((merchant) => merchant.type === "LINE")
      );
    },
    async fetchSkus() {
      if (!this.query.branch_id) {
        this.query.branch_id = this.currentBranch.id;
      }

      this.showLoading = true;

      try {
        const params = {
          ...this.query,
        };

        const { data } = await productApi.getSkus(params);

        this.skus = data.data;
        this.totalRows = data.meta.total;

        this.productSkus = this.skus.map((sku) => {
          return {
            ...sku,
            max_quantity_orig: sku.max_quantity,
          };
        });
      } catch (error) {
        console.error(error);
        alert("取得商品資料錯誤");
      }
      this.showLoading = false;
    },
    checkPermission(permissions) {
      return this.permissionChecker.check(permissions);
    },
    handlePageChange(newPage) {
      this.query.page = newPage;
    },
    handlePerPageChange(newPerPage) {
      this.query.per_page = newPerPage;
      this.query.page = 1;
    },
    handlePageReset() {
      const currentPage = parseInt(this.query.page, 10);

      if (currentPage === 1) {
        updateUrl(this.query, this.$store, this.$router);
      } else {
        this.query.page = 1;
      }
      this.fetchSkus();
    },
    handleBranchChange() {
      console.debug("Branch changed to:", this.selectedBranch);
      this.query.branch_id = this.selectedBranch;
      this.query.page = 1; // Reset to the first page when branch changes
      this.fetchSkus();
    },
    toggleEditMode(row) {
      this.$set(row, "isEditing", !row.isEditing);
    },
    async updateProductSkuMaxQuantity(row, max_quantity) {
      const result = await this.$swal.fire({
        title: "確認修改",
        text: `將『${row.product.title} / ${row.sku_name}』可銷售數量從 ${row.max_quantity_orig} 調整成 ${max_quantity} 嗎？`,
        showCancelButton: true,
        confirmButtonText: "確認修改",
        cancelButtonText: "取消",
      });

      if (result.value) {
        try {
          const organizationId = this.$store.state.general.organization.id;
          await productApi.updateProductSkuMaxQuantity(organizationId, row.id, {
            max_quantity,
          });

          row.max_quantity_orig = max_quantity;
          this.$set(row, "isEditing", false);

          this.$swal.fire({
            title: "成功",
            type: "success",
            text: "更新成功",
          });
        } catch (error) {
          this.$swal("失敗", error.response.data.message, "error");
        }
      } else {
        row.max_quantity = row.max_quantity_orig;
        this.$set(row, "isEditing", false);
      }
    },
  },
};
</script>

<style>
.modal-backdrop {
  opacity: 0.5;
}
</style>
